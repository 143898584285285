import { UserContext } from '@celito.clients/provider';
import { postload } from '@celito.clients/services';
import { Link, Text } from '@fluentui/react-components';
import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import LogoIcon from '../../../../../assets/src/images/footer-logo.png';
import { LocalizationString } from '../../../../../assets/src/localization';
import { FooterComponentProps } from './footer.model';
import styles from './footer.module.css';

interface FooterComponentViewProps extends FooterComponentProps {
  calculateIdleMinutes: (lastTimestamp: string) => number;
}

const FooterView: React.FC<FooterComponentViewProps> = ({
  itemList,
  calculateIdleMinutes,
  ...rest
}) => {
  const { lastTimeStamp } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const showIdleSession = searchParams.get('showIdleSession') === 'true';
  const [differenceInMinutes, setDifferenceInMinutes] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const difference = calculateIdleMinutes(lastTimeStamp);

      setDifferenceInMinutes(difference);
    }, 60000); // Update every minute (60000 ms)

    return () => clearInterval(intervalId);
  }, [lastTimeStamp]);

  const getFooterLinks = (itemList: Array<{ label: string; url: string }>) => {
    return itemList.map(
      (item: { label: string; url: string }, index: number) => (
        <React.Fragment key={index}>
          <Link
            className={`${styles.textStyle}`}
            href={item.url}
            target={'_blank'}
            rel="noreferrer"
            style={{ textDecoration: 'underline' }}
            appearance="subtle"
          >
            {item.label}
          </Link>
          {index !== itemList.length - 1 && (
            <Text className={`${styles.textStyle} ${styles.spaceStyle}`}>
              |
            </Text>
          )}
        </React.Fragment>
      )
    );
  };

  useEffect(() => {
    postload.start();
  }, []);

  return (
    <div className={`${styles.wrapper} ${styles.rows} ${styles.container}`}>
      <Text className={`${styles.textStyle} ${styles.one}`}>
        {LocalizationString.CELITO_COPYWRIGHT_LABEL}
      </Text>
      <div className={`${styles.rows} ${styles.two}`}>
        <img
          src={LogoIcon}
          alt="footer-logo"
          className={`${styles.logoStyle}`}
        />
      </div>
      <div className={`${styles.three}`}>
        {showIdleSession && (
          <Text size={100}>
            (Session is idle for {differenceInMinutes} minutes)
          </Text>
        )}
        {getFooterLinks(itemList)}
      </div>
    </div>
  );
};
export default FooterView;
