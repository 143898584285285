export enum LayoutRuleConditionEnum {
  VALUE = 'VALUE',
  VALUE_FROM_CONDITIONS = 'VALUE_FROM_CONDITIONS',
}

export type LayoutRulesConfigurationSchema = {
  jsonRuleCondition: LayoutRuleConditionEnum;
  layoutConfig: LayoutWithValueConfig | JsonWithValueFromConditionsConfigSchema;
  defaultLayoutRules?: LayoutRulesDataSchema;
};

export interface LayoutWithValueConfig {
  value: LayoutRulesDataSchema;
}

export interface LayoutRuleProperties {
  conditions: TopLevelCondition;
  event: Event;
  name?: string;
  priority?: number;
}
export interface JsonWithValueFromConditionsConfigSchema {
  rules: LayoutRuleProperties[];
}

export interface Event {
  type: string;
  params?: LayoutRulesDataSchema;
}

// TODO: move these types to core lib. Remove Layout prefix from all types
// to make it more generic
export type LayoutRulesDataSchema = {
  isEditable?: boolean;
  isRequired?: boolean;
  isVisible?: boolean;
  isHidden?: boolean;
  type?: 'manual' | 'auto';
  picklistFilter?: string[];
  message?: string[];
};

export interface ConditionProperties {
  fact: string;
  operator: string;
  value: { fact: string } | any;
  path?: string;
  priority?: number;
  params?: Record<string, any>;
  name?: string;
}
export interface ConditionWithAttributeProperties
  extends Omit<ConditionProperties, 'fact' | 'value'> {
  attribute: string;
  value: { fact: string };
}

export type NestedCondition =
  | ConditionProperties
  | TopLevelCondition
  | ConditionWithAttributeProperties;

export type AllConditions = {
  all: NestedCondition[];
  name?: string;
  priority?: number;
};

export type AnyConditions = {
  any: NestedCondition[];
  name?: string;
  priority?: number;
};

export type NotConditions = {
  not: NestedCondition;
  name?: string;
  priority?: number;
};

export type ConditionReference = {
  condition: string;
  name?: string;
  priority?: number;
};
export type TopLevelCondition =
  | AllConditions
  | AnyConditions
  | NotConditions
  | ConditionReference;

export type InferValueFromFieldsSchema = {
  fieldName: string;
  lookUpColumnName: string;
  orderOfConsideration: number;
};
