import { DocumentTypeObjectKeyEnum } from '@celito.clients/enums';
import { Field, TextField } from '@celito.clients/shared';
import { ObjectAttributeDefinition } from '@celito.clients/types';
import { debounce, errorToast } from '@celito.clients/utils';
import { textFieldStyles } from 'libs/shared/src/lib/text-field/src/text-field.styles';
import { get } from 'lodash';
import { useMemo } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import * as yup from 'yup';

import { DocumentTypeService } from '../../services/document-type';
import { TFieldValues as TF } from '../screens/edit/controller';
import Select from './picker/select';

type DocumentTypeProps = {
  handleIsInList: (value: boolean) => void;
  attributes: Record<DocumentTypeObjectKeyEnum, ObjectAttributeDefinition>;
};

export default function DocumentType({
  attributes,
  handleIsInList,
}: Readonly<DocumentTypeProps>) {
  const { setValue, trigger } = useFormContext<TF>();
  const styles = textFieldStyles();
  const [documentTypeCode, documentTypeName, documentTypeDisabled] =
    useWatch<TF>({
      name: ['documentTypeCode', 'documentTypeName', 'documentTypeDisabled'],
    }) as [string, string, boolean];

  const loadOptions = useMemo(
    () =>
      debounce(async (documentTypeName) => {
        const {
          data: { data },
        } = await DocumentTypeService.getDocumentTypes(documentTypeName);

        return data.map((type) => {
          return {
            value: Object.assign(type.code, {
              doesNotHaveSubTypes: type.doesNotHaveSubTypes ?? true,
            }),
            label: type.label,
            name: type.name,
          };
        });
      }, 500),
    []
  );

  return (
    <>
      <Controller<TF, 'documentType'>
        name={'documentType'}
        shouldUnregister
        render={({ field, fieldState: { error } }) => {
          const errorMessage = error?.message;
          return (
            <div>
              <Field
                size="large"
                label={attributes.label?.label}
                helperTextLabel={attributes.label?.helpText}
                className={styles.field}
                validationMessage={errorMessage}
                validationState={errorMessage ? 'error' : 'none'}
                required
              >
                <Select
                  placeholder={undefined}
                  errorMessage={errorMessage}
                  loadOptions={loadOptions}
                  isDisabled={documentTypeDisabled}
                  value={
                    field.value
                      ? {
                          label: field.value,
                          code: documentTypeCode,
                          name: documentTypeName,
                          doesNotHaveSubTypes: true,
                        }
                      : undefined
                  }
                  onCreateOption={(inputValue) => {
                    handleIsInList(false);
                    setValue('documentTypeCode', '');
                    setValue('documentTypeCodeIsDisabled', false);
                    setValue('subTypesDisabled', false);

                    // NOTE: Keeping the document name same as the title
                    field.onChange(inputValue);

                    setValue('newDocumentType', true);
                  }}
                  onChange={(inputValue, { action }) => {
                    handleIsInList(true);
                    try {
                      if (action === 'select-option') {
                        const value = yup
                          .object()
                          .shape({
                            label: yup.string().required(),
                            value: yup.string().required(),
                            name: yup.string().required(),
                          })
                          .validateSync(inputValue);
                        field.onChange(value.label);
                        setValue('documentTypeCode', value.value.toString());
                        setValue('documentTypeName', value.name);
                        setValue('documentTypeCodeIsDisabled', true);
                        setValue('newDocumentType', false);
                        setValue(
                          'doesNotHaveSubTypes',
                          get(value, 'value.doesNotHaveSubTypes', true)
                        );
                        trigger('documentTypeCode');
                      } else if (action === 'clear') {
                        handleIsInList(false);
                        field.onChange('');
                        setValue('documentTypeCode', '');
                        setValue('documentTypeName', '');
                        setValue('documentTypeCodeIsDisabled', false);

                        setValue('documentSubTypeCode', '');
                        setValue('documentSubTypeCode', '');
                        setValue('documentSubType', '');
                        setValue('documentSubTypeCodeIsDisabled', false);
                      }
                    } catch (e) {
                      errorToast({
                        message: 'Something Wrong',
                      });
                    }
                  }}
                  label={'document-type'}
                />
              </Field>
            </div>
          );
        }}
      />
      <Controller<TF>
        name={'documentTypeCode'}
        shouldUnregister
        render={({ field, fieldState }) => {
          return (
            <CodeField attribute={attributes.code} {...{ field, fieldState }} />
          );
        }}
      />
    </>
  );
}
const CodeField = ({
  field,
  attribute,
  fieldState: { error },
}: {
  field: ControllerRenderProps<TF>;
  attribute: ObjectAttributeDefinition;
  fieldState: ControllerFieldState;
}) => {
  const [documentTypeCodeIsDisabled, documentTypeDisabled] = useWatch<TF>({
    name: ['documentTypeCodeIsDisabled', 'documentTypeDisabled'],
  });
  const errorMessage = error?.message;
  return (
    <div>
      <TextField
        value={typeof field.value === 'string' ? field.value : ''}
        onChange={(e) => {
          field.onChange(e.currentTarget.value);
        }}
        label={attribute?.label}
        helperLabelText={attribute?.helpText}
        required={true}
        disabled={!!documentTypeCodeIsDisabled || !!documentTypeDisabled}
        errorMessage={errorMessage}
      />
    </div>
  );
};
