import { differenceInMinutes } from 'date-fns';

import { FooterComponentProps } from './footer.model';
import FooterView from './footer.view';

// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const FooterController = (props: FooterComponentProps) => {
  const calculateIdleMinutes = (lastTimestamp: string): number => {
    const lastTime = new Date(lastTimestamp); // Parse the timestamp into a Date object
    const now = new Date(); // Get the current time
    const idleMinutes = differenceInMinutes(now, lastTime); // Calculate the difference in minutes

    if (isNaN(idleMinutes)) return 0;
    return idleMinutes;
  };

  return <FooterView {...{ ...props, calculateIdleMinutes }} />;
};
