import { LocalizationString } from '@celito.clients/assets';
import { AttributeTypeEnum } from '@celito.clients/enums';
import * as yup from 'yup';

import { FormMode } from '../document-templates/types';
import { MyObjectAttributeDefinition } from './types';

const formStateSchema = yup.object().shape({
  subTypesDisabled: yup.boolean(),
  documentTypeDisabled: yup.boolean(),
  newDocumentType: yup.boolean(),
  newDocumentSubType: yup.boolean(),
  formMode: yup.string().default(FormMode.CREATE),
  codeHidden: yup.string(),
  nameHidden: yup.string(),
  doesNotHaveSubTypes: yup.boolean(),
  hiddenDocumentType: yup.mixed(),
  hiddenSubType: yup.mixed(),
});

enum Errors {
  ATMOST_12_CHARACTERS = 'Field must be at most 12 characters',
}

export const formSchema = formStateSchema.concat(
  yup.object().shape({
    documentType: yup
      .string()
      .trim()
      .required('Document Type is Required')
      .meta({
        label: 'Document Type',
        name: 'documentType',
        dataTypeKeyForFE: AttributeTypeEnum.Reference,
        isEditable: true,
        isMandatory: true,
      } satisfies MyObjectAttributeDefinition),
    documentTypeCode: yup
      .string()
      .trim()
      .max(12, Errors.ATMOST_12_CHARACTERS)
      .required('Document Type Code is Required')
      .meta({
        label: 'Document Type Code',
        name: 'documentTypeCode',
        dataTypeKeyForFE: AttributeTypeEnum.Reference,
        isEditable: true,
        isMandatory: true,
      } satisfies MyObjectAttributeDefinition),
    documentTypeName: yup.string().default(''),
    documentTypeCodeIsDisabled: yup.boolean().default(false),
    documentSubType: yup.string().meta({
      label: 'Document Sub Type',
      name: 'documentSubType',
      dataTypeKeyForFE: AttributeTypeEnum.Reference,
      isEditable: true,
    } satisfies MyObjectAttributeDefinition),
    documentSubTypeCode: yup
      .string()
      .trim()
      .max(12, Errors.ATMOST_12_CHARACTERS)
      .when('documentSubType', {
        is: (val: never) => !!val,
        then(schema) {
          return schema.required('Document Sub Type Code is Required');
        },
      })
      .meta({
        label: 'Document Sub Type Code',
        name: 'documentSubTypeCode',
        dataTypeKeyForFE: AttributeTypeEnum.Reference,
        isEditable: true,
      } satisfies MyObjectAttributeDefinition),
    documentSubTypeName: yup.string().default(''),
    documentSubTypeCodeIsDisabled: yup.boolean().default(false),
    isRawFileDownloadAllowed: yup
      .boolean()
      .required(LocalizationString.REQUIRED_MSG)
      .meta({
        label: 'Is Raw File Download Allowed?',
        name: 'isRawFileDownloadAllowed',
        dataTypeKeyForFE: AttributeTypeEnum.RadioYesNo,
        isMandatory: true,
        isEditable: true,
      } satisfies MyObjectAttributeDefinition),
    isAdminReviewRequired: yup.boolean().meta({
      label: 'Is Biz Admin Review Required for GxP Documents?',
      name: 'isAdminReviewRequired',
      dataTypeKeyForFE: AttributeTypeEnum.RadioYesNo,
      isMandatory: true,
      isEditable: true,
    } satisfies MyObjectAttributeDefinition),

    isTrainable: yup
      .boolean()
      .required()
      .meta({
        label: 'Is Trainable?',
        name: 'isTrainable',
        dataTypeKeyForFE: AttributeTypeEnum.RadioYesNo,
        isMandatory: true,
        isEditable: true,
      } satisfies MyObjectAttributeDefinition),
    followUpTimePeriodInMonths: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .positive('Must be greater than 0')
      .nullable()
      .when('isFollowUpRequired', {
        is: (val: string) => !!val && val !== 'false',
        then(schema) {
          return schema.required(LocalizationString.REQUIRED_MSG);
        },
      }),
    templateName: yup
      .string()
      .required(LocalizationString.REQUIRED_MSG)
      .meta({
        label: 'Template Name',
        name: 'templateName',
        dataTypeKeyForFE: AttributeTypeEnum.Dropdown,
        isMandatory: true,
        isEditable: true,
      } satisfies MyObjectAttributeDefinition),
    effectivenessPeriodInDays: yup
      .number()
      .min(0, 'Must be greater than or equal to 0')
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required(LocalizationString.REQUIRED_MSG)
      .meta({
        label: 'Effectiveness in Days',
        isMandatory: true,
        name: 'effectivenessPeriodInDays',
        dataTypeKeyForFE: AttributeTypeEnum.Number,
        isEditable: true,
      } satisfies MyObjectAttributeDefinition),
    followUpTriggerPeriodInDays: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .positive('Must be greater than 0')
      .nullable()
      .when('isFollowUpRequired', {
        is: (val: string) => !!val && val !== 'false',
        then(schema) {
          return schema.required(LocalizationString.REQUIRED_MSG);
        },
      }),
    isFollowUpRequired: yup
      .boolean()
      .required()
      .meta({
        label: 'Follow Up Required?',
        name: 'isFollowUpRequired',
        isMandatory: true,
        dataTypeKeyForFE: AttributeTypeEnum.RadioYesNo,
        isEditable: true,
      } satisfies MyObjectAttributeDefinition),
    isGoverningDocumentRequired: yup
      .boolean()
      .required(LocalizationString.REQUIRED_MSG)
      .meta({
        label: 'Governing Document ID Required?',
        name: 'isGoverningDocumentRequired',
        isMandatory: true,
        dataTypeKeyForFE: AttributeTypeEnum.RadioYesNo,
        isEditable: true,
      } satisfies MyObjectAttributeDefinition),
    labelFormat: yup
      .string()
      .meta({
        label: 'Label Format',
        name: 'labelFormat',
        isMandatory: false,
        dataTypeKeyForFE: AttributeTypeEnum.PlainText,
        isEditable: true,
      } satisfies MyObjectAttributeDefinition)
      .test(
        'has-hash-and-dash',
        'The Label Format must include at least one "##" placeholder and at least one "-"',
        (value) => !value || (value.includes('##') && value.includes('-'))
      ),
    isActive: yup.boolean(),
  })
);

export type FormSchemaKeys = keyof typeof formSchema.fields;
