import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { WarningIcon } from '@celito.clients/assets';
import { UserContext } from '@celito.clients/provider';
import { ConfirmDialog } from '@celito.clients/shared';
import { differenceInMinutes } from 'date-fns';
import Cookies from 'js-cookie';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

export const SessionConstants = {
  MAX_TIME: 60,
  WARNING_TIME: 1,
  COOKIE_NAME: 'lastTimeStamp',
  TIMEOUT_KEY: 'timeout-show-warning',
  COUNTDOWN_SECONDS: 60,
  SYNC_INTERVAL_MS: 100,
  USER_EVENTS: [
    'click',
    'load',
    'scroll',
    'mousemove',
    'mousedown',
    'keypress',
  ] as const,
} as const;

const sessionChannel = new BroadcastChannel('session-timeout');

const SessionTimeout = () => {
  const { handleLastTimeStamp } = useContext(UserContext);

  const [countdown, setCountdown] = useState<number>(
    SessionConstants.COUNTDOWN_SECONDS
  );
  const [showWarning, setShowWarning] = useState(false);

  const endTimeRef = useRef<number | null>(null);
  const isCountingDown = useRef(false);
  const isMaster = useRef<boolean>(false);

  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const intervals = useRef<{
    warning?: ReturnType<typeof setInterval>;
    countdown?: ReturnType<typeof setInterval>;
    startTimer?: ReturnType<typeof setTimeout>;
  }>({});

  const clearAllTimers = useCallback(() => {
    Object.values(intervals.current).forEach(
      (interval) => interval && clearInterval(interval)
    );
    intervals.current = {};
    isCountingDown.current = false;
  }, []);

  const updateCountdown = useCallback(() => {
    if (!endTimeRef.current) return;

    const now = Date.now();
    const timeLeft = Math.ceil((endTimeRef.current - now) / 1000);

    if (timeLeft <= 0) {
      clearAllTimers();
      localStorage.removeItem(SessionConstants.TIMEOUT_KEY);
      broadcastLogout();
      return;
    }

    setCountdown(timeLeft);
  }, [clearAllTimers]);

  const startCountdownTimer = useCallback(() => {
    if (isCountingDown.current) return;

    if (intervals.current.countdown) {
      clearInterval(intervals.current.countdown);
    }

    isCountingDown.current = true;
    updateCountdown();

    intervals.current.countdown = setInterval(updateCountdown, 1000);
  }, [updateCountdown]);

  const broadcastActivity = () => {
    sessionChannel.postMessage({ type: 'ACTIVITY' });
  };

  const broadcastEndTime = (endTime: number) => {
    if (!endTimeRef.current) {
      // Only broadcast if we don't have an endTime
      sessionChannel.postMessage({
        type: 'SET_END_TIME',
        data: { endTime },
      });
      endTimeRef.current = endTime;
    }
  };

  const broadcastWarning = (show: boolean, endTime?: number) => {
    if (show && endTime && !endTimeRef.current) {
      // Only set endTime if we don't have one
      endTimeRef.current = endTime;
    }

    sessionChannel.postMessage({
      type: 'SHOW_WARNING',
      data: { show, endTime: endTimeRef.current },
    });
    setShowWarning(show);

    if (!show) {
      endTimeRef.current = null;
      isCountingDown.current = false;
    }
  };

  const broadcastLogout = () => {
    sessionChannel.postMessage({ type: 'LOGOUT' });
    const timeoutDuration = SessionConstants.SYNC_INTERVAL_MS * 2;
    setTimeout(() => {
      removeTimeStamp();
      instance.logoutRedirect();
    }, timeoutDuration);
  };

  const startCountdown = () => {
    if (intervals.current.countdown) {
      clearInterval(intervals.current.countdown);
    }

    if (isMaster.current && !endTimeRef.current) {
      // Only set new endTime if we're master and don't have one
      const endTime = Date.now() + SessionConstants.COUNTDOWN_SECONDS * 1000;
      broadcastEndTime(endTime);
    }

    startCountdownTimer();
  };

  // Rest of the utility functions remain the same
  const isCookieEnabled = () => {
    try {
      document.cookie = 'cookietest=1';
      const isEnabled = document.cookie.indexOf('cookietest=') !== -1;
      document.cookie = 'cookietest=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
      return isEnabled;
    } catch {
      return false;
    }
  };

  const setTimeStamp = (timeStamp: string) => {
    if (isCookieEnabled()) {
      Cookies.set(SessionConstants.COOKIE_NAME, timeStamp, {
        expires: SessionConstants.MAX_TIME / 1440,
      });
    } else {
      localStorage.setItem(SessionConstants.COOKIE_NAME, timeStamp);
    }
    handleLastTimeStamp(timeStamp);
  };

  const getTimeStamp = () => {
    if (isCookieEnabled()) {
      return Cookies.get(SessionConstants.COOKIE_NAME);
    }
    return localStorage.getItem(SessionConstants.COOKIE_NAME);
  };

  const removeTimeStamp = () => {
    if (isCookieEnabled()) {
      Cookies.remove(SessionConstants.COOKIE_NAME);
    } else {
      localStorage.removeItem(SessionConstants.COOKIE_NAME);
    }
  };

  const warningInactive = (timeString: string | null | undefined) => {
    if (intervals.current.warning) {
      clearInterval(intervals.current.warning);
    }

    if (!timeString) return;

    intervals.current.warning = setInterval(() => {
      const pastDate = new Date(timeString);
      const now = new Date();
      const minPast = differenceInMinutes(now, pastDate);

      if (
        minPast >= SessionConstants.MAX_TIME - SessionConstants.WARNING_TIME &&
        !showWarning
      ) {
        clearAllTimers();
        localStorage.setItem(SessionConstants.TIMEOUT_KEY, 'true');

        if (isMaster.current && !endTimeRef.current) {
          const endTime =
            Date.now() + SessionConstants.COUNTDOWN_SECONDS * 1000;
          broadcastWarning(true, endTime);
        }
        startCountdown();
      } else if (minPast >= SessionConstants.MAX_TIME) {
        clearAllTimers();
        removeTimeStamp();
        localStorage.removeItem(SessionConstants.TIMEOUT_KEY);
        broadcastLogout();
      }
    }, 1000);
  };

  const timeChecker = useCallback(() => {
    if (intervals.current.startTimer) {
      clearTimeout(intervals.current.startTimer);
    }

    intervals.current.startTimer = setTimeout(() => {
      const storedTimeStamp = getTimeStamp();
      warningInactive(storedTimeStamp);
    }, 1000);
  }, []);

  const resetTimer = useCallback(() => {
    if (!showWarning) {
      if (intervals.current.warning) {
        clearInterval(intervals.current.warning);
      }
      if (intervals.current.startTimer) {
        clearTimeout(intervals.current.startTimer);
      }

      if (isAuthenticated) {
        const timeStamp = new Date().toISOString();
        setTimeStamp(timeStamp);
        timeChecker();
        broadcastActivity();
      } else {
        removeTimeStamp();
      }
    }
  }, [isAuthenticated, timeChecker, showWarning]);

  useEffect(() => {
    const masterTab = !localStorage.getItem('session-master-tab');
    if (masterTab) {
      localStorage.setItem('session-master-tab', 'true');
      isMaster.current = true;
    }

    const handleBeforeUnload = () => {
      if (isMaster.current) {
        localStorage.removeItem('session-master-tab');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    const handleMessage = (event: MessageEvent) => {
      const { type, data } = event.data;

      switch (type) {
        case 'SET_END_TIME':
          if (!endTimeRef.current) {
            // Only set endTime if we don't have one
            endTimeRef.current = data.endTime;
            if (!isCountingDown.current) {
              startCountdownTimer();
            }
          }
          break;
        case 'SHOW_WARNING':
          setShowWarning(data.show);
          if (data.show && data.endTime && !endTimeRef.current) {
            // Only set endTime if we don't have one
            endTimeRef.current = data.endTime;
            if (!isCountingDown.current) {
              startCountdownTimer();
            }
          } else if (!data.show) {
            endTimeRef.current = null;
            isCountingDown.current = false;
            clearAllTimers();
          }
          break;
        case 'LOGOUT': {
          clearAllTimers();
          setShowWarning(false);
          const timeoutDuration = SessionConstants.SYNC_INTERVAL_MS * 2;
          setTimeout(() => {
            instance.logoutRedirect();
          }, timeoutDuration);
          break;
        }
        case 'ACTIVITY':
          if (!showWarning && isAuthenticated) {
            const timeStamp = new Date().toISOString();
            setTimeStamp(timeStamp);
            timeChecker();
          }
          break;
      }
    };

    sessionChannel.addEventListener('message', handleMessage);

    const storedWarning = localStorage.getItem(SessionConstants.TIMEOUT_KEY);

    if (storedWarning === 'true') {
      setShowWarning(true);
      if (isMaster.current && !endTimeRef.current) {
        // Only set new endTime if we're master and don't have one
        const endTime = Date.now() + SessionConstants.COUNTDOWN_SECONDS * 1000;
        broadcastEndTime(endTime);
        startCountdown();
      }
    }

    SessionConstants.USER_EVENTS.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    if (isAuthenticated) {
      timeChecker();
    }

    return () => {
      SessionConstants.USER_EVENTS.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      window.removeEventListener('beforeunload', handleBeforeUnload);
      sessionChannel.removeEventListener('message', handleMessage);
      clearAllTimers();
      if (isMaster.current) {
        localStorage.removeItem('session-master-tab');
      }
    };
  }, [
    clearAllTimers,
    instance,
    isAuthenticated,
    resetTimer,
    showWarning,
    startCountdownTimer,
    timeChecker,
  ]);

  const onConfirmClicked = () => {
    localStorage.removeItem(SessionConstants.TIMEOUT_KEY);
    broadcastWarning(false);
    clearAllTimers();
    resetTimer();
    setCountdown(60);
  };

  const onCancelClicked = () => {
    localStorage.removeItem(SessionConstants.TIMEOUT_KEY);
    clearAllTimers();
    broadcastLogout();
  };

  return (
    <ConfirmDialog
      open={showWarning}
      title={`Your session is about to expire in ${countdown} seconds`}
      description="Your organization's policy enforces automatic sign out after a period of inactivity. Do you want to stay signed in?"
      iconSrc={WarningIcon}
      onConfirmClicked={onConfirmClicked}
      onCancelClicked={onCancelClicked}
      secondaryButtonText="Sign out now"
      primaryButtonText="Stay Signed in"
      dontShowCloseIcon
      isBlocking
    />
  );
};

export default SessionTimeout;
